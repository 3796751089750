<template>
  <div>
    <el-popover :visible-arrow="isShow" popper-class="centlin" class="centlin1" placement="bottom-end" width="1078" trigger="hover">
      <div>
        <el-button class="zk" type="text" v-if="showExpand" @click="expand()">展开</el-button>
        <div v-if="type == 1" class="linyu">
          <ul class="ul1">
            <li :key="item" v-for="item in citationMagsList.slice(0, 5)">
              <a
                @click="
                  () => {
                    handleDetails(item)
                  }
                "
                >{{ item }}</a
              >
            </li>
          </ul>
          <ul class="ul2">
            <li :key="item" v-for="item in citationMagsList.slice(5, 10)">
              <a
                @click="
                  () => {
                    handleDetails(item)
                  }
                "
                >{{ item }}</a
              >
            </li>
          </ul>
          <ul class="ul3">
            <li :key="item" v-for="item in referenceMagsList.slice(0, 5)">
              <a
                @click="
                  () => {
                    handleDetails(item)
                  }
                "
                >{{ item }}</a
              >
            </li>
          </ul>
          <ul class="ul4">
            <li :key="item" v-for="item in referenceMagsList.slice(5, 10)">
              <a
                @click="
                  () => {
                    handleDetails(item)
                  }
                "
                >{{ item }}</a
              >
            </li>
          </ul>
        </div>
        <div v-else class="linyu">
          <ul class="ul1">
            <li :key="item" v-for="item in magsList.slice(0, 5)">
              <a
                @click="
                  () => {
                    handleDetails(item)
                  }
                "
                >{{ item }}</a
              >
            </li>
          </ul>
          <ul class="ul2">
            <li :key="item" v-for="item in magsList.slice(5, 10)">
              <a
                @click="
                  () => {
                    handleDetails(item)
                  }
                "
                >{{ item }}</a
              >
            </li>
          </ul>
        </div>
      </div>
      <el-button slot="reference" size="qk" icon="el-icon-caret-bottom" type="text">领域期刊列表</el-button>
    </el-popover>
    <!-- 期刊领域概况 -->
    <div>
      <h2 class="center_title">期刊领域概况</h2>
      <div class="conter_1">
        <div v-for="(item, index) in dictionary" :key="index" :class="item.number ? 'con_01 cuser01' : 'con_01'" @click="goRoute(item)">
          <NumRoll v-if="item.number" :numbers="item.number + ''" :colors="colors[index]" />
          <div class="con_01_title">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div class="center_2 center_3">
      <el-tabs v-model="activeName" type="card" class="tabs2">
        <el-tab-pane label="引证图谱" name="first">
          <!-- 引证图谱 -->
          <div>
            <h2 class="clearfix">
              <div class="fr btn sdClias">
                <el-radio-group v-model="chooseYearRange">
                  <el-radio-button v-for="item in timeOptions" :key="item.key" :label="item.key">{{ item.value }}</el-radio-button>
                </el-radio-group>
                <span class="tj_year">统计年份:</span>
                <el-select v-model="chooseYearVal" placeholder="请选择" style="width:100px;top:-4px;right:-10px;">
                  <el-option v-for="item in yearOptions" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </div>
            </h2>
            <div class="conter_1" style="width:100%;position: relative">
              <div class="postion btn">
                <span class="tj_year">前</span>
                <el-select v-model="quoteVal" placeholder="请选择" style="width:70px;top:-4px;right:-10px;">
                  <el-option v-for="item in rankOptions" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
                <span class="tj_year1">个</span>
              </div>
              <div class="postion1 btn">
                <span class="tj_year">前</span>
                <el-select v-model="citationVal" placeholder="请选择" style="width:70px;top:-4px;right:-10px;">
                  <el-option v-for="item in rankOptions" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
                <span class="tj_year1">个</span>
              </div>
              <div
                :id="randomId"
                :style="{
                  width: '100%',
                  height: '420px',
                  background: '#f5f7fd',
                  padding: '20px 0'
                }"
              />
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="引证统计" name="second">
          <!-- 引证统计 -->
          <div>
            <h2 class="clearfix">
              <div class="fr btn">
                <el-radio-group v-model="citationTimeValue">
                  <el-radio-button v-for="item in timeOptions" :key="item.key" :label="item.key">{{ item.value }}</el-radio-button>
                </el-radio-group>
                <el-button
                  type="text"
                  icon="el-icon-xz"
                  @click="
                    () => {
                      handleDownload('citation')
                    }
                  "
                  >下载</el-button
                >
              </div>
            </h2>
            <SimpleTable
              ref="citationTable"
              :tableData="citationTableData"
              :columnData="citationTimeValue === 3 ? citationColumnThree : citationColumn"
              :initObj="citationPageObj"
              @onChangeSort="
                (prop, order) => {
                  handleChangeSort(prop, order, 'citation')
                }
              "
              @onChangePageData="
                (pageObj) => {
                  getCitationData(pageObj)
                }
              "
              @onClickTitle="
                (item) => {
                  handleClusterDetail(item, 'citation')
                }
              "
            />
          </div>
        </el-tab-pane>
        <el-tab-pane label="引用统计" name="third">
          <!--引用统计  -->
          <div>
            <h2 class="clearfix">
              <div class="fr btn">
                <el-radio-group v-model="quoteTimeValue">
                  <el-radio-button v-for="item in timeOptions" :key="item.key" :label="item.key">{{ item.value }}</el-radio-button>
                </el-radio-group>
                <el-button
                  type="text"
                  icon="el-icon-xz"
                  @click="
                    () => {
                      handleDownload('quote')
                    }
                  "
                  >下载</el-button
                >
              </div>
            </h2>
            <SimpleTable
              ref="quoteTable"
              :tableData="quoteTableData"
              :columnData="quoteTimeValue === 3 ? quoteColumnThree : quoteColumn"
              :initObj="quotePageObj"
              @onChangeSort="
                (prop, order) => {
                  handleChangeSort(prop, order, 'quote')
                }
              "
              @onChangePageData="
                (pageObj) => {
                  getReferenceData(pageObj)
                }
              "
              @onClickTitle="
                (item) => {
                  handleClusterDetail(item, 'quote')
                }
              "
            />
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog :visible.sync="dialogVisible" :fullscreen="true" title="领域期刊列表" custom-class="zkgd">
      <div v-if="type == 1">
        <div class="zk_title">
          <div style="margin-right: 15px">引证期刊</div>
          <div style="padding-left: 0;margin-left: 15px;">引用期刊</div>
        </div>
        <ul class="linyugd" style="padding-right: 15px">
          <li :key="item" v-for="item in citationMagsList">
            <a
              @click="
                () => {
                  handleDetails(item)
                }
              "
              >{{ item }}</a
            >
          </li>
        </ul>
        <ul class="linyugd" style="padding-left: 20px">
          <li :key="item" v-for="item in referenceMagsList">
            <a
              @click="
                () => {
                  handleDetails(item)
                }
              "
              >{{ item }}</a
            >
          </li>
        </ul>
      </div>
      <div v-else>
        <ul class="linyugd domain" style="padding-left: 20px">
          <li :key="item" v-for="item in magsList">
            <a
              @click="
                () => {
                  handleDetails(item)
                }
              "
              >{{ item }}</a
            >
          </li>
        </ul>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import SimpleTable from '@/components/SimpleTable'
import { Statistics, GetCitationGraph, GetQuoteGraph, GetAllCitationQuote, GetDomainMags, GetMagByName, GetReference, GetCitation, exportCitation, exportReference } from '@/api/analysis'
import { GetDomainType, GetSameCatMags } from '@/api/index'
import NumRoll from '@/components/NumRoll'
import { baseUrl } from '@/assets/js/base64'
import { formatNum } from '@/utils'
export default {
  name: 'Overview',
  components: {
    NumRoll,
    SimpleTable
  },
  computed: {
    ...mapGetters({
      magId: 'magId',
      magsStatus: 'magsStatus'
    })
  },
  watch: {
    magId() {
      //强制要求更新
      this.dictionary.map((item) => {
        this.$set(item, 'number', '')
      })
      this.getDomainData()
      this.getDomainMags()
      if (this.activeName === 'first') {
        const citationPromise = this.getCitationGraphData()
        const quotePromise = this.getQuoteGraphData()
        const totalPromise = this.getCitationQuoteData()
        Promise.all([citationPromise, quotePromise, totalPromise]).then(([res1, res2, res3]) => {
          if (res1 && res2 && res3) {
            // 更新echarts图
            this.echart()
          }
        })
      }
      if (this.activeName === 'second') {
        this.getCitationData()
      }
      if (this.activeName === 'third') {
        this.getReferenceData()
      }
      // 强制要求更新
      // this.dictionary.map((item) => {
      //   this.$set(item, 'number', '')
      // })
      // this.getDomainData()
      // this.getDomainMags()
      // this.getReferenceData()
      // this.getCitationData()
    },
    citationTimeValue(nv) {
      this.citationPageObj.sort = nv === 3 ? 'totalCount3,desc' : 'totalCount5,desc'
      this.getCitationData()
      this.$refs.citationTable.clearSort()
    },
    quoteTimeValue(nv) {
      this.quotePageObj.sort = nv === 3 ? 'totalCount3,desc' : 'totalCount5,desc'
      this.getReferenceData()
      this.$refs.quoteTable.clearSort()
    },
    citationVal() {
      this.echart()
    },
    quoteVal() {
      this.echart()
    },
    chooseYearVal(nv) {
      this.chooseDataKey = nv
      // 选择的时间之后，将三五年清除选中
      this.chooseYearRange = 0
      const citationPromise = this.getCitationGraphData()
      const quotePromise = this.getQuoteGraphData()
      Promise.all([citationPromise, quotePromise]).then(([res1, res2]) => {
        if (res1 && res2) {
          // 更新echarts图
          this.echart()
        }
      })
    },
    chooseYearRange(nv) {
      if (nv === 3) {
        this.chooseDataKey = 'totalCount3'
      } else if (nv === 5) {
        this.chooseDataKey = 'totalCount5'
      }
      if (nv !== 0) {
        const citationPromise = this.getCitationGraphData()
        const quotePromise = this.getQuoteGraphData()
        Promise.all([citationPromise, quotePromise]).then(([res1, res2]) => {
          if (res1 && res2) {
            // 更新echarts图
            this.echart()
          }
        })
      }
    },
    activeName(nv) {
      if (nv === 'first') {
        // //强制要求更新
        // this.dictionary.map((item) => {
        //   this.$set(item, 'number', '')
        // })
        // this.getDomainData()
        // this.getDomainMags()
        return
      }
      if (nv === 'second') {
        this.getCitationData()
        return
      }
      if (nv === 'third') {
        this.getReferenceData()
      }
    },
    magsStatus(nv) {
      this.getDomainType()
    }
  },
  data() {
    return {
      randomId: 'echarts-dom',
      activeName: 'first',
      dictionary: [
        { key: 'domainArticleCount', title: '领域发文量' },
        { key: 'domainInstitutionCount', title: '领域发文机构' },
        // { key: 'domainScholarCount', title: '领域学者' },
        { key: 'magArticleCount', title: '本刊发文量' },
        { key: 'magInstitutionCount', title: '本刊发文机构' }
        // { key: 'whiteAuthorCount', title: '白名单作者' }
      ],
      colors: ['#ec5463', '#eea806', '#6eb91a', '#2db897', '#0ba9e1', '#5672f0'], // 期刊领域概况 颜色
      dialogVisible: false,
      isShow: false,
      yearOptions: yearOptions,
      chooseYearVal: 'count1',
      rankOptions: [
        {
          value: '5',
          label: '5'
        },
        {
          value: '10',
          label: '10'
        }
      ],
      citationVal: '5',
      quoteVal: '5',
      chooseYearRange: 5,
      // 近几年选项的options
      timeOptions: [
        {
          key: 5,
          value: '近5年'
        },
        {
          key: 3,
          value: '近3年'
        }
      ],
      chooseDataKey: 'totalCount5',
      citationList: [],
      quoteList: [],
      totalData: {}, // 中间大球的数据
      showExpand: false,
      citationMagsList: [],
      referenceMagsList: [],
      // 引证统计
      // 引证统计表格
      citationTableData: [],
      citationPageObj: {
        page: 1,
        size: 10
      }, // 引证统计分页
      citationTimeValue: 5, // 当前 引证统计 时间 选中项
      citationColumn: [
        {
          title: '引证刊名',
          prop: 'ctiMagName',
          special: true,
          width: '300px',
          notSortable: true
        },
        ...recentFiveYear,
        {
          title: '合计',
          prop: 'totalCount5'
        }
      ], // 引证统计 表格header 内容（近五年）
      citationColumnThree: [
        {
          title: '引证刊名',
          prop: 'ctiMagName',
          special: true,
          width: '300px',
          notSortable: true
        },
        ...recentThreeYear,
        {
          title: '合计',
          prop: 'totalCount3'
        }
      ], // 引证统计 表格header 内容（近三年）
      // 引用统计
      quoteTableData: [],
      quotePageObj: {
        page: 1,
        size: 10
      },
      quoteTimeValue: 5, // 当前 引用统计 时间 选中项
      quoteColumnThree: [
        {
          title: '引用刊名',
          prop: 'refMagName',
          special: true,
          width: '300px',
          notSortable: true
        },
        ...recentThreeYear,
        {
          title: '合计',
          prop: 'totalCount3'
        }
      ], // 引用统计 表格header 内容(近三年)
      quoteColumn: [
        {
          title: '引用刊名',
          prop: 'refMagName',
          special: true,
          width: '300px',
          notSortable: true
        },
        ...recentFiveYear,
        {
          title: '合计',
          prop: 'totalCount5'
        }
      ], // 引用统计 表格header 内容(近五年)
      type: '', // 设置类型 1为引证引用关系，2为分类关系，3为自选
      magsList: [] // type不为1时的列表
    }
  },
  mounted() {
    if (this.magId) {
      this.getDomainData()
      this.getReferenceData()
      this.getCitationData()
      this.getDomainType()
      const citationPromise = this.getCitationGraphData()
      const quotePromise = this.getQuoteGraphData()
      const totalPromise = this.getCitationQuoteData()
      Promise.all([citationPromise, quotePromise, totalPromise]).then(([res1, res2, res3]) => {
        if (res1 && res2 && res3) {
          // 更新echarts图
          this.echart()
        }
      })
    }
  },
  methods: {
    // 处理所有下载的函数
    handleDownload(downloadType = '') {
      if (downloadType === 'citation') {
        window.open(`${exportCitation}?magId=${this.magId}&page=${this.citationPageObj.page - 1}&size=${this.citationPageObj.size}&sort=${this.citationPageObj.sort || ''}`, '_self')
        return
      }
      if (downloadType === 'quote') {
        window.open(`${exportReference}?magId=${this.magId}&page=${this.quotePageObj.page - 1}&size=${this.quotePageObj.size}&sort=${this.quotePageObj.sort || ''}`, '_self')
        return
      }
    },
    // 点击聚类列表标题跳转详情页
    handleClusterDetail(clusterItem, type) {
      if (type === 'citation') {
        var searchName = clusterItem.ctiMagName
      } else if (type === 'quote') {
        var searchName = clusterItem.refMagName
      }
      GetMagByName({ magName: searchName }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { id = '' } = data
          if (id !== '') {
            this.$router.push({ path: '/details', query: { searchId: id, magId: this.magId } }) // 跳转地址
          }
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 获取引证统计数据
    async getCitationData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.magId,
        page: page - 1,
        size,
        sort: this.citationPageObj.sort
      }
      GetCitation(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.citationPageObj = {
            ...this.citationPageObj,
            page,
            size,
            total: totalElements
          }
          this.citationTableData = content
        } else {
          const { message } = res.data
          this.$message.error(message)
        }
      })
    },
    // 获取引用统计数据
    async getReferenceData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.magId,
        page: page - 1,
        size,
        sort: this.quotePageObj.sort
      }
      GetReference(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.quotePageObj = {
            ...this.quotePageObj,
            page,
            size,
            total: totalElements
          }
          this.quoteTableData = content
        } else {
          const { message } = res.data
          this.$message.error(message)
        }
      })
    },
    // 排序的改变了
    handleChangeSort(prop, order, tableName) {
      if (tableName === 'quote') {
        // 聚类引用趋势列表排序改变
        let sortValue = ''
        if (!order || order === '') {
          sortValue = this.quoteTimeValue === 3 ? 'totalCount3,desc' : 'totalCount5,desc'
        } else {
          if (order === 'ascending') {
            sortValue = prop + ',asc'
          } else {
            sortValue = prop + ',desc'
          }
        }
        this.quotePageObj.sort = sortValue
        this.getReferenceData()
      } else if (tableName === 'citation') {
        // 聚类引证趋势列表排序改变
        let sortValue = ''
        if (!order || order === '') {
          sortValue = this.citationTimeValue === 3 ? 'totalCount3,desc' : 'totalCount5,desc'
        } else {
          if (order === 'ascending') {
            sortValue = prop + ',asc'
          } else {
            sortValue = prop + ',desc'
          }
        }
        this.citationPageObj.sort = sortValue
        this.getCitationData()
      }
    },
    handleDetails(chooseItem = '') {
      GetMagByName({ magName: chooseItem }).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { id = '' } = data
          if (id !== '') {
            this.$router.push({ path: '/details', query: { searchId: id, magId: this.magId } }) // 跳转地址
          }
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    getDomainMags() {
      const params = {
        magId: this.magId
      }
      GetDomainMags(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { citationMags = [], referenceMags = [] } = res.data.data
          this.showExpand = citationMags.length > 10 || referenceMags.length > 10
          this.citationMagsList = citationMags
          this.referenceMagsList = referenceMags
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 跳转
    goRoute(val) {
      if (val.number && val.number !== '0') {
        const { key = '' } = val
        if (key === 'whiteAuthorCount') {
          this.$router.push('/whitelist')
          return
        }
        if (key === 'magArticleCount' || key === 'magInstitutionCount') {
          this.$emit('onAnchorScroll', key)
          return
        }
      }
    },
    // 获取引证和引用中间球的数据
    getCitationQuoteData() {
      const params = {
        id: this.magId
      }
      return GetAllCitationQuote(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.totalData = data
          return true
        } else {
          const { message } = res.data
          this.$message.error(message)
          return false
        }
      })
    },
    // 获取引证图谱中引用数据
    getQuoteGraphData() {
      let sortVal = ''
      if (this.chooseYearRange === 0) {
        sortVal = `${this.chooseYearVal},desc`
      } else {
        sortVal = `totalCount${this.chooseYearRange},desc`
      }
      const params = {
        magId: this.magId,
        page: 0,
        size: 10,
        sort: sortVal
      }
      return GetQuoteGraph(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.quoteList = data
          this.quoteList.reverse()
          return true
        } else {
          const { message } = res.data
          this.$message.error(message)
          return false
        }
      })
    },
    // 获取引证图谱中引证数据
    getCitationGraphData() {
      let sortVal = ''
      if (this.chooseYearRange === 0) {
        sortVal = `${this.chooseYearVal},desc`
      } else {
        sortVal = `totalCount${this.chooseYearRange},desc`
      }
      const params = {
        magId: this.magId,
        page: 0,
        size: 10,
        sort: sortVal
      }
      return GetCitationGraph(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.citationList = data
          this.citationList.reverse()
          return true
        } else {
          const { message } = res.data
          this.$message.error(message)
          return false
        }
      })
    },
    // 获取期刊领域情况数据
    getDomainData() {
      const params = {
        magId: this.magId
      }
      Statistics(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.dictionary.map((item) => {
            //往里面放置number的值
            this.$set(item, 'number', formatNum(data[item.key]))
          })
        }
      })
    },
    echart() {
      let jnL // 站位假数据
      let jnR // 站位假数据
      let dxDataL //左侧宽高
      let dxDataR //右侧宽高
      let numsL // 左侧个数
      let numsR // 右侧个数
      let zbDataL = [] // 左侧坐标
      let zbDataR = [] // 右侧坐标
      if (this.quoteVal === '5') {
        // 5 个的宽高
        dxDataL = [180, 48] // 宽 高
        numsL = 5
        jnL = 5
      } else {
        // 10 个的宽高
        dxDataL = [180, 24] // 宽 高
        numsL = 10
        jnL = 10
      }
      if (this.citationVal === '5') {
        // 5 个的宽高
        dxDataR = [180, 48] // 宽 高
        numsR = 5
        jnR = 5
      } else {
        // 10 个的宽高
        dxDataR = [180, 24] // 宽 高
        numsR = 10
        jnR = 10
      }
      // 左 的坐标算法
      for (let i = 0; i < numsL; i++) {
        zbDataL.push([150, i * (560 / (numsL - 1))])
      }
      // 右  的坐标算法
      for (let i = 0; i < numsR; i++) {
        zbDataR.push([850, i * (560 / (numsR - 1))])
      }
      // 随机数
      let randomNumBoth = function(maxNum, minNum, decimalNum) {
        let max = 0
        let min = 0
        // eslint-disable-next-line no-unused-expressions
        minNum <= maxNum ? ((min = minNum), (max = maxNum)) : ((min = maxNum), (max = minNum))
        switch (arguments.length) {
          case 1:
            return Math.floor(Math.random() * (max + 1))
          case 2:
            return Math.floor(Math.random() * (max - min + 1) + min)
          case 3:
            return (Math.random() * (max - min) + min).toFixed(decimalNum)
          default:
            return Math.random()
        }
      }
      let citationList = this.citationList.filter((item) => {
        return item[this.chooseDataKey] !== 0 && item[this.chooseDataKey] !== '0'
      }) //右侧 引证
      let quoteList = this.quoteList.filter((item) => {
        return item[this.chooseDataKey] !== 0 && item[this.chooseDataKey] !== '0'
      }) //左侧 引用
      citationList = citationList.slice(this.citationList.length - (this.citationVal - 0) >= 0 ? this.citationList.length - (this.citationVal - 0) : 0, this.citationList.length)
      quoteList = quoteList.slice(this.quoteList.length - (this.quoteVal - 0) >= 0 ? this.quoteList.length - (this.quoteVal - 0) : 0, this.quoteList.length)
      const citationGraphList = [] // 右侧引证数据
      const quoteGraphList = [] // 左侧引用数据
      const linkArray = [] // 线的数据
      //左侧
      for (let i = 0; i < quoteList.length; i += 1) {
        const quoteItem = {
          id: i + 1,
          searchName: quoteList[i].refMagName,
          name: quoteList[i].refMagName,
          x: quoteList[i][this.chooseDataKey], // 本刊引用
          y: [[quoteList[i].influence1 || 0, quoteList[i].influence2 || 0, quoteList[i].influence3 || 0], randomNumBoth(1000, 2000)], // 影响因子 + 发行量
          fixed: true,
          value: zbDataL[i], // 坐标
          symbolSize: quoteList[0].ctiMagName === 'zwd99' ? '' : dxDataL,
          label: {
            fontSize: 14,
            color: '#666',
            formatter: function(value) {
              var res = value.name
              if (res.length > 12) {
                res = res.substring(0, 11) + '..'
              }
              return res
            }
          },
          category: 2,
          itemStyle: {
            normal: {
              borderType: 'solid',
              borderColor: '#e7e7e7',
              borderWidth: 1,
              color: '#fff'
            }
          }
        }
        const tempVal = quoteList[i][this.chooseDataKey]
        const linkItem = {
          source: i + 1 + '',
          target: '0',
          label: {
            show: true,
            formatter: function(item) {
              return tempVal // 数值
            },
            color: '#666',
            align: 'center',
            verticalAlign: 'middle'
          },
          lineStyle: {
            normal: {
              // color: "#12b5d0",
            }
          }
        }
        quoteGraphList.push(quoteItem)
        if (quoteList[0] && quoteList[0].ctiMagName === 'zwd99') {
        } else {
          linkArray.push(linkItem)
        }
      }
      //右侧
      for (let i = 0; i < citationList.length; i += 1) {
        const citationItem = {
          id: i + 1 + quoteList.length,
          searchName: citationList[i].ctiMagName,
          name: citationList[i].ctiMagName === 'zwd99' ? '' : citationList[i].ctiMagName,
          x: citationList[i][this.chooseDataKey], // 本刊引用
          y: [[citationList[i].influence1 || 0, citationList[i].influence2 || 0, citationList[i].influence3 || 0], randomNumBoth(1000, 2000)], // 影响因子 + 发行量
          fixed: true,
          value: zbDataR[i], // 坐标
          symbolSize: citationList[0].ctiMagName === 'zwd99' ? '' : dxDataR,
          label: {
            fontSize: 14,
            color: '#666',
            formatter: function(value) {
              var res = value.name
              if (res.length > 12) {
                res = res.substring(0, 11) + '..'
              }
              return res
            }
          },
          category: 1,
          itemStyle: {
            normal: {
              borderType: 'solid',
              borderColor: '#e7e7e7',
              borderWidth: 1,
              color: '#fff'
            }
          }
        }
        const tempVal = citationList[i][this.chooseDataKey]
        const linkItem = {
          source: '0',
          target: i + 1 + quoteList.length + '',
          label: {
            show: true,
            formatter: function(item) {
              return tempVal // 数值
            },
            color: '#666',
            align: 'center',
            verticalAlign: 'middle'
          },
          lineStyle: {
            normal: {
              // color: "#12b5d0",
            }
          }
        }
        citationGraphList.push(citationItem)
        if (citationList[0].ctiMagName === 'zwd99') {
        } else {
          linkArray.push(linkItem)
        }
      }
      let zdxData = []
      // 左侧 动效 坐标数据
      for (let i = 0; i < quoteList.length; i++) {
        if (quoteList[0].refMagName === 'zwd99') {
        } else {
          zdxData.push({
            coords: [zbDataL[i], [500, 280]]
          })
        }
      }
      let rdxData = []
      // 右侧 动效 坐标数据
      for (let i = 0; i < citationList.length; i++) {
        if (citationList[0].ctiMagName === 'zwd99') {
        } else {
          rdxData.push({
            coords: [[500, 280], zbDataR[i]]
          })
        }
      }
      const dxData = zdxData.concat(rdxData)
      // 中间标题过长换行处理
      var zfc = {}
      zfc.mixTextOverflow = function(text, length) {
        if (text.replace(/[\u4e00-\u9fa5]/g, 'aa').length <= length) {
          return text
        } else {
          var _length = 0
          var outputText = ''
          for (var i = 0; i < text.length; i++) {
            if (/[\u4e00-\u9fa5]/.test(text[i])) {
              _length += 2
            } else {
              _length += 1
            }
            if (_length > length) {
              break
            } else {
              outputText += text[i]
            }
          }
          return outputText
        }
      }
      let name = ''
      name = this.totalData.name
      var centerName = ''
      let length = ''
      var jmz = {}
      jmz.GetLength = function(str) {
        return str.replace(/[\u0391-\uFFE5]/g, 'aa').length //先把中文替换成两个字节的英文，在计算长度
      }
      length = jmz.GetLength(name)
      if (length > 24) {
        let row = ''
        let remainder = length % 24
        if (remainder == 0) {
          row = parseInt(length / 24)
        } else {
          row = parseInt(length / 24) + 1
        }
        let arr = []
        for (var i = 0; i < row; i++) {
          if ((i + 1) * 24 < length) {
            arr.push(zfc.mixTextOverflow(name, 24) + '\n')
            name = name.split(zfc.mixTextOverflow(name, 24))[1]
          }
        }
        centerName = arr.join('') + name
      } else {
        centerName = name
      }
      //中间数据
      const data = [
        // 中间的
        {
          id: 0,
          name: centerName,
          searchId: this.magId,
          tooltip: {
            formatter: function(item) {
              if (item) {
                return `<ul style="text-align: left;font-size: 12px">
                        <li>${item.data.name}</li>
                        <li>影响因子：知网：${item.data.y[0][0]} 万方：${item.data.y[0][1]} 百度：${item.data.y[0][2]}</li>
                        <li>ISSN号：${item.data.y[0][3]}</li>
                      </ul>
               `
              }
            }
          },
          symbolSize: [250, 250],
          value: [500, 280],
          x: 752,
          y: [[this.totalData.influence1 || 0, this.totalData.influence2 || 0, this.totalData.influence3 || 0, this.totalData.issn || ''] || 0, randomNumBoth(1000, 2000)], // 影响因子 + 发行量
          symbol: baseUrl,
          fixed: true,
          // draggable: false,
          category: 1,
          label: {
            fontSize: 20,
            color: '#fff'
          }
        },
        ...quoteGraphList,
        ...citationGraphList
      ]
      let that = this
      // 暂时不展示的内容
      // <li>本刊引用：${item.data.x} 总被引:${item.data.x}</li>
      // <li>发文量：${item.data.y[1]}</li>
      // <li>ISSN号:1231321</li>
      let myChart = this.$echarts.init(document.getElementById(this.randomId))
      myChart.setOption(
        {
          backgroundColor: '#f5f7fd',
          xAxis: {
            show: false,
            type: 'value'
          },
          yAxis: {
            show: false,
            type: 'value'
          },
          tooltip: {
            formatter: function(item) {
              if (item && item.value) {
                let name = item.data.name || ''
                let data0 = item.data.y ? item.data.y[0][0] : ''
                let data1 = item.data.y ? item.data.y[0][1] : ''
                let data2 = item.data.y ? item.data.y[0][2] : ''
                return `<ul style="text-align: left;font-size: 12px">
                          <li>${name}</li>
                          <li>影响因子：知网：${data0} 万方：${data1} 百度：${data2}</li>
                        </ul>
                 `
              }
            }
          },
          series: [
            {
              type: 'graph',
              zlevel: 5,
              draggable: false,
              coordinateSystem: 'cartesian2d', // 使用二维的直角坐标系（也称笛卡尔坐标系）
              symbol: 'rect',
              // symbolOffset: ['15%', 0],
              label: {
                normal: {
                  show: true
                }
              },
              data: data,
              links: linkArray,
              lineStyle: {
                normal: {
                  opacity: 1,
                  color: '#53B5EA',
                  curveness: 0.2,
                  width: '2'
                }
              },
              itemStyle: {
                emphasis: {
                  shadowColor: 'rgba(0, 0, 0, 0.2)',
                  shadowBlur: 10,
                  borderWidth: 0
                }
              }
            },
            {
              type: 'lines',
              coordinateSystem: 'cartesian2d',
              z: 1,
              zlevel: 2,
              animation: false,
              effect: {
                show: true,
                period: 5,
                trailLength: 0.01,
                symbolSize: 12,
                symbol: 'pin',
                loop: true,
                color: 'rgba(55,155,255,0.5)'
              },
              lineStyle: {
                normal: {
                  color: '#22AC38',
                  width: 0,
                  curveness: 0.2
                }
              },
              data: dxData
            }
          ]
        },
        true
      )
      if (myChart._$handlers.click) {
        myChart._$handlers.click.length = 0
      }
      myChart.on('click', function(param) {
        // 关系图的节点被点击时此方法被回调
        let { searchName = '', searchId = '' } = param.data
        if (searchId && searchId !== '') {
          that.$router.push({ path: '/details', query: { searchId, magId: that.magId } }) // 跳转地址
        } else {
          GetMagByName({ magName: searchName }).then((res) => {
            if (res && res.data && res.data.status === 0) {
              const { data } = res.data
              if (data) {
                const { id = '' } = data
                if (id !== '') {
                  that.$router.push({ path: '/details', query: { searchId: id, magId: that.magId } }) // 跳转地址
                }
              }
            } else {
              const { message = '' } = res.data
              this.$message.error(message)
            }
          })
        }
      })
    },
    expand() {
      this.dialogVisible = true
    },
    // 获取刊物领域设置信息 1为引证引用关系，2为分类关系，3为自选
    getDomainType() {
      let params = {
        magId: this.magId
      }
      GetDomainType(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { type } = res.data.data
          this.type = String(type)
          if (this.type == 1) {
            this.getDomainMags()
          } else if (this.type == 2) {
            this.getSameCatMags()
          } else if (this.type == 3) {
            this.magsList = res.data.data.mags
            this.showExpand = this.magsList.length > 10
          }
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 获取分类刊物列表 radio为2
    getSameCatMags() {
      let params = {
        magId: this.magId
      }
      GetSameCatMags(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { mags } = res.data.data
          this.magsList = mags
          this.showExpand = this.magsList.length > 10
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.con_01 {
  width: 270px;
  height: 110px;
  background: #f5f7fd;
  margin-bottom: 5px;
  position: relative;
}

.conter_1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 15px 0 25px;
}

.con_01_title {
  color: #666;
  font-size: 14px;
  margin-top: 10px;
}

.conter_3 {
  padding: 15px 0 25px;
}

.tj_year {
  color: #999;
  font-size: 14px;
  position: relative;
  top: -3px;
  right: -10px;
}
.postion {
  position: absolute;
  top: 40px;
  left: 30px;
  z-index: 100;
}
.postion1 {
  position: absolute;
  top: 40px;
  right: 30px;
  z-index: 100;
}
.tj_year1 {
  color: #999;
  font-size: 14px;
  position: relative;
  top: -3px;
  right: -10px;
}
.linyu {
  display: flex;
}
.linyu ul li {
  line-height: 34px;
}
.linyu ul li a {
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}
.ul1 {
  width: 242px;
}
.ul2 {
  width: 310px;
}
.ul3 {
  width: 262px;
}
.ul4 {
  flex: 1;
}
.centlin1 {
  position: absolute;
  top: 32px;
  right: 30px;
  z-index: 200;
}
.posion {
  position: relative;
}
.zk {
  position: absolute;
  top: 10px;
  right: 20px;
  color: #fff;
}
.linyugd {
  float: left;
  width: 50%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
}
.linyugd li {
  width: 50%;
  line-height: 34px;
  text-align: left;
}
.linyugd li a {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  cursor: pointer;
}
.linyugd li a:hover {
  color: rgba(255, 255, 255, 1);
}
.zk_title {
  width: 1200px;
  margin-left: -600px;
  color: #fff;
  position: fixed;
  top: 60px;
  left: 50%;
  display: flex;
  padding-bottom: 20px;
}
.zk_title div {
  width: 50%;
  text-align: left;
  padding-left: 26px;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: bold;
  line-height: 46px;
  border-bottom: 1px solid #fff;
}
.tabs2 .el-radio-button {
  margin-left: 10px;
}
.center_2 .el-tabs--card > .el-tabs__header {
  padding-top: 0;
}
.center_2 {
  padding: 0 0 30px;
}
.domain {
  width: 100%;
}
.domain li {
  width: 25%;
}
</style>
